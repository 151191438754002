<template>
  <Transition name="site-nav">
    <nav v-if="menu.active" class="site-nav">
      <AtomsList :items="items" custom-class="site-nav" @clicked="menu.close()">
        <template #link>
          <SvgoDiamond class="site-nav-diamond" />
        </template>
      </AtomsList>
    </nav>
  </Transition>
</template>

<script lang="ts" setup>
const { menu } = useCzn()

watch(useRoute(), () => (menu.close()), { immediate: true })

useHead(() => ({
  htmlAttrs: {
    style: menu.active ? 'overflow: hidden' : '',
  },
}))

const { button } = useDghGroq()

const query = groq`*[_type == "menu" && slug.current == $slug][0].items[] {
    ${button()},
    "options": {
      !('hideOnLarger' in options) => {
        "hide-on-desktop": 'hideOnDesktop' in options,
      },
      "hide-on-larger": 'hideOnLarger' in options,
      "hide-on-smaller": 'hideOnSmaller' in options,
      !('hideOnSmaller' in options) => {
        "hide-on-mobile": 'hideOnMobile' in options,
      },
    },
    children[] {
      ${button()},
      "options": {
        "hide-on-desktop": 'hideOnDesktop' in options,
        "hide-on-tablet": 'hideOnTablet' in options,
        "hide-on-mobile": 'hideOnMobile' in options,
      },
    }
}`
const { data: items, refresh } = useSanityQuery(query, { slug: 'main' })

onMounted(() => {
  if (!items) refresh()
})
</script>

<style lang="scss">
.site-nav {
  position: fixed;
  inset: 0;
  z-index: 800;
  height: 100%;
  background: var(--background);
  transition: czn.transition(var(--ts), (transform));

  padding-top: 10rem;

  font-size: 1.2vmax;

  @include media('<desktop') {
    font-size: 1.2rem;
  }

  @include media('<tablet') {
    padding-top: 6rem;
  }

  &-enter-from,
  &-leave-to {
    transform: translateY(-100vh);
  }

  .site-nav {
    &-list {
      display: flex;
      justify-content: space-evenly;
      overflow: hidden;
      align-items: flex-start;
      padding-top: 14vh;

      @include media('<desktop') {
        justify-content: flex-start;
        height: 100%;
        overflow: hidden scroll;
        margin: 0;
        padding: 0 0 4rem;
        flex-flow: column;
        align-items: center;
      }
    }
    &-diamond {
      margin-top: 0.5em;
      height: 1em;
      color: currentColor;
    }
    &-item {
      display: flex;
      flex-flow: column;
      align-items: center;
      font-family: var(--font-emph);
    }
    &-link {
      flex-flow: column;
      justify-content: center;
      padding-left: czn.$rg;
      padding-right: czn.$rg;
    }
    &-child {
      &-item {
        font-family: var(--font-body);
      }
    }
  }
}
</style>
